@media screen and (max-width: 1420px) and (min-width: 1024) {

}

@media screen and (max-width: 1024px) and (min-width: 620px){


}

@media screen and (max-width: 620px) {
  
    .home-page-back {
        width: 80vw;
        flex-wrap: wrap;
    }

    .under-construction {
        height: 300px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction:column;
    }

    .under-construction img {
        margin-left: auto;
        margin-right: auto;
        width: 150px;
        object-fit: cover;
        border-radius: 15px;
    }
    
    .under-construction-back h1 {
        text-align: center;
        line-height: 50px;
        height: 100px;
        font-size: 20px;
        font-weight: 700;
    }

    /*About carousel*/
    .about-carousel-button-background{
        display: none;
    }

    .about-company-desc{
        margin-top: 0.75em;
        line-height: 1.5em;
        font-size: 13px;
        font-weight: normal;
        text-align: left;
    }
    .about-company-name {
        font-size: 25px;
    }

    .about-experience-title {
        font-size: 45px;
    }

    .about-company-role {
        font-size: 12px;
    }
    
    .about-company-date {
        font-size: 12px;
    }
    
    .about-company-location {
        font-size: 12px;
    }

    
    /*carousel about page*/
    .about-carousel-container-outside h1 {
        font-size: 13px;
    }

    .about-carousel-container-outside {
        padding: 10px;
    }

    /*Portfolio carousel*/
    .carousel-button-background {
        display: none;
    }


    .carousel-description .carousel-title {
        font-size: 13px;
    }

    .carousel-description .carousel-details {
        font-size: 10px;
    }

    /*tech container needs more*/
    .my-technologies-container h1 {
        font-size: 13px;
    }
    
    .my-technologies-container {
        padding: 10px;
    }


    .Download-CV {
        width: 80vw;
        flex-wrap: wrap;
    }
    
    .Download-CV .first-column {
        justify-content: center;
        flex-direction: column;
        margin-top: 2em;
    }

    

    .profile-img-box {
        display: flex;
        flex-wrap: wrap;
        justify-content:center;
    }


    .second-column {
        display: flex;
        flex-direction: column;
        margin-top: 2em;
    }

    
    .nav-test-bar {
        display:none;
    }



    /*--------------------------*/
/* Dropdown Button */
  /* The container <div> - needed to position the dropdown content */
    .dropmenu {
    cursor: pointer;
    position: fixed;
    display: inline;
    top:20px;
	right:8%;
    z-index: 7;
    }

    .dropmenu .dropmenu-button {
    width:40px;
	height:40px;
    font-size: 12px;
	background-color:rgb(62, 94, 86);
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
    cursor: pointer;
    }

    /* Dropdown button on hover & focus */
    .dropmenu-button:hover, .dropmenu-button:focus {
        background-color: #2980B9;
      }
  /* Dropdown Content (Hidden by Default) */
  .drop-down-cont {
    display: none;
    background-color: #f1f1f1;
    min-width: 90px;
    position: absolute;
    right: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 8;
  }
  
  /* Links inside the dropdown */
  .drop-down-cont li {
    color: black;
    padding: 10px 10px;
    text-decoration: none;
    display: block;
    text-align: center;
  }

  .drop-down-cont li:hover{
    background-color: rgb(120, 228, 223); 
  }


  /* Change color of dropdown links on hover */
  .drop-down-cont ul:hover {background-color: rgb(158, 34, 34)}
  
  /* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
  .show {display:block;}

  /*-----------------------------------------------*/
  /*Example Page*/
  .past-project-back{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }


  .exampleICONS{
      width: 100%;
  }

  .example-proj-inner-footer a {
      width: 200px;
  }

  #header-prop{
    font-size: 45px;
    font-weight: 100;
  }

    .past-project-inner{
        padding: 10px;
    }

    .carousel-container-portfolio{
        padding: 10px;
    }

  .past-project-inner h2 {
    padding-bottom: 10px;
    font-size: 35px;
    font-weight: 300;
    flex-grow: 4;
    text-align: center;
}
    .example-proj-inner h5{
        text-align: center;
        padding-left: 0px;
    }
}


@media screen and (max-width: 460px) {
    .nav-test-bar {
        display:none;
    }
}

@media screen and (max-width: 380px) {
    .nav-test-bar {
        display:none;
    }
}

