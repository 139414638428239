@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@300;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Anonymous+Pro');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');


:root {
    --color-red-light: rgb(231, 77, 60);
    /* #E74D3C */
    --color-red-dark: rgb(192, 57, 43);
    /* #C0392B */
    --color-orange-light: rgb(230, 125, 34);
    /* #E67D22 */
    --color-orange-dark: rgb(211, 84, 0);
    /* #D35400 */
    --color-yellow-light: rgb(241, 196, 15);
    /* #F1C40F */
    --color-yellow-dark: rgb(243, 156, 18);
    /* #F39C12 */
    --color-green-light: rgb(46, 204, 112);
    /* #2ECC70 */
    --color-green-dark: rgb(39, 174, 96);
    /* #27AE60 */
    --color-turquoise-light: rgb(26, 188, 156);
    /* #1ABC9C */
    --color-turquoise-dark: rgb(22, 160, 132);
    /* #16A084 */
    --color-blue-light: rgb(52, 152, 219);
    /* #3498DB */
    --color-blue-dark: rgb(41, 128, 185);
    /* #2980B9 */
    --color-purple-light: rgb(156, 89, 182);
    /* #9C59B6 */
    --color-purple-dark: rgb(142, 68, 173);
    /* #8E44AD */
    --color-white-light: rgb(236, 240, 241);
    /* #ECF0F1 */
    --color-white-dark: rgb(189, 195, 199);
    /* #BDC3C7 */
    --color-gray-light: rgb(149, 165, 166);
    /* #95A5A6 */
    --color-gray-dark: rgb(127, 140, 141);
    /* #7D8C8D */
    --color-black-light: rgb(52, 73, 94);
    /* #34495E */
    --color-black-dark: rgb(44, 62, 80);
    /* #2c3e50 */
    --color-black-dark2: rgb(27, 23, 23);
    --color-black-light2: rgb(34, 32, 32);;
    /* 2d3032 */
    --color-black-dark-light: rgb(45,48,50);
}


* {
    margin: 0;
    padding: 0;
    font-family: 'Urbanist', sans-serif;
}



#header-prop{
    text-align: center;
    line-height: 200px;
    height: 200px;
    font-size: 72px;
    font-weight: 700;
    color: var(--color-white-light);
    text-shadow: 0 0 10px var(--color-black-light);
    background: var(--color-black-dark2);
    /* linear-gradient(135deg, var(--color-turquoise-light) 0%,
var(--color-blue-dark) 100%); */
    margin: 75px 0 0 0;
}

.cont-col {
    height: 100px;
    color: black;
    width: 100px;
    top: 100px;
    align-items: center;
    background-color: blueviolet;
}

.head-main {
    color: aliceblue;
    height: 100px;
    width: 200px;
    float: left;

}

#home-p-down {
    text-align: center;
    color: rgb(127, 140, 141);
    outline: 1px 1px;
    outline-color: black;
}


/*--------------------------*/
/*Footer*/
footer{
    padding: 30px 0; 
    display: flex;
    justify-content: space-evenly;
    background-color: var(--color-black-light2);
    box-shadow: 0 50vh 0 50vh var(--color-black-light2);
}

footer .col{
    text-align: center;
    display: block;
    width: 240px;
    color: var(--color-white-light);
}

footer p{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 4px;
}

footer ul{
    padding-left: 0;
    list-style-type: none;
}
footer .col:nth-child(2) ul li {
    display: inline;
}

footer ul img{
    margin-left: 4px;
    height: 20px;
    display: inline;
    vertical-align: middle;
}


footer a{
    color: var(--color-white-light);
    text-decoration: none !important;
    
}

footer .col:first-child li:hover{
    opacity: 0.5;
    cursor: pointer;
}


/*button that scrolls up*/
#topBtn {
    display: block;
    width: 100%;
    margin: auto; /*optional centering of image*/
    z-index: 99; /* Make sure it does not overlap */
    width: 15px;
    padding-top: 2px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
  }
  
 

  #topBtn-background{
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    cursor: pointer; /* Add a mouse pointer on hover */
    width: 25px;
    height: 25px;
    background-color: #555;
    border-radius: 50%;
  }

  #topBtn-background:hover {
    background-color: rgb(228, 225, 225); /* Add a dark-grey background on hover */
    transform: scale(1.5);
  }

/*Controls main page background*/
#About-Container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(52,73,94,1);
    background-image:linear-gradient(0deg, rgba(52,73,94,1) 10%, rgba(255,255,255,1) 10%, rgba(255,255,255,1) 96%, var(--color-black-dark2) 96%);
    /*background-image: linear-gradient(0deg, #fff 44.44%, #d4e7fe 44.44%, #d4e7fe 0%, #fff 0%, #fff 94.44%, rgb(60, 83, 107) 94.44%, rgb(52, 73, 94) 100%);
    background: linear-gradient(0deg, rgba(52,73,94,1) 70%, rgba(255,255,255,1) 71%, rgba(255,255,255,1) 95%, rgba(52,73,94,1) 96%);
    background-size: 10px 1220px;*/
}

.Download-CV {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    flex-direction: row;
    border-radius: 15px;
    box-shadow: 0 0 9px 0 rgba(112, 112, 112, 0.16);
    width: 85vw;
    z-index: 2;
    padding: 2em;
    max-width: 1400px;
}

.first-column {
    flex-grow: 2;
    align-items: center;
    text-align: center;
}

.first-column div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.first-column .profile-img{
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 99em;
        overflow: hidden;
        border: 3px solid #889ab1;
        height: 200px;
        width: 200px;
        object-fit: cover;
        object-position: 50% 40%;
}


.profile-links {
    margin-top: 1em; 
    max-height: 50px;
    display: inline-block;
}


.second-column {
    margin: 20px;
    font-family: 'Open Sans', sans-serif;
}
.second-column h2{
    color: #555;
    font-weight: 300;
}
.second-column h1 {
    font-size: 45px;
    font-weight: 600;
    flex-grow: 4;
    color: rgb(0, 0, 0);
    padding-top: 1px;
    padding-bottom: 10px
}


#email-back-col{
    flex-direction: column;
    display: flex;
    background-color: var(--color-black-dark2);
    /*background-image: linear-gradient(0deg, rgba(52,73,94,1) 33%, rgba(255,255,255,1) 34%, rgba(255,255,255,1) 64%, rgba(52,73,94,1) 66%);*/
    /*background-image: linear-gradient(0deg, #fff 44.44%, #d4e7fe 44.44%, #d4e7fe 0%, #fff 0%, #fff 94.44%, rgb(60, 83, 107) 94.44%, rgb(52, 73, 94) 100%);*/
    /*background: rgba(52,73,94,1);
    background-size: 10px 1220px;*/
}

#Contact-mail-header {
    padding-bottom: 10px;
    font-size: 40px;
    color: #889ab1;
    font-family: 'Open Sans', sans-serif;
}

.email-container-box {
    width: 60vw;
    z-index: 2;
    padding: 2em;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}


.email-container-send-box {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 9px 0 rgba(112, 112, 112, 0.16);
    padding: 1em;
    margin-top: 1em;
}

.email-container-send-box form {
    display: flex;
    flex-direction: column;
}

.email-group {
    display: flex;
    margin-bottom: 1em;
    flex-direction: column;
}

.error-message {
    margin-top: 1em;
    color: red;
}


.Main-nav-bar{
    position: fixed;
    display: flex;
    justify-content: space-between;
    top: 0;
    left: 0;
    right: 0;
    height: 75px;
    padding: 0 15px;
    z-index: 5;
    background-color: var(--color-black-dark2);
    box-shadow: 0 0 10px var(--color-black-dark2);
}
/*used when scrolling down*/

.Main-nav-bar2{
    position: fixed;
    display: flex;
    justify-content: space-between;
    top: 0;
    left: 0;
    right: 0;
    height: 75px;
    padding: 0 15px;
    z-index: 5;
    background-color: var(--color-black-light2);
    box-shadow: 0 0 10px var(--color-black-light2);
}

.Main-nav-img img {
    height: 50px;
    width: 50px;
    float: left;
    object-fit: contain;
    padding: 8px 0;
}

.nav-test-bar {
    position: relative;
    padding-top: 10px;
    justify-content: space-between;
}

.nav-test-bar>ul{
    justify-content: flex-end;
    align-items: center;
    list-style-type: none;
    
}

/*.nav-test-bar>ul li{
    display: inline-block;
    height: 50px;
    width: 110px;
    text-align: center;
    vertical-align:middle;
    line-height: 75px;
    font-size: 22px;
    border-radius: 15px;
    color: var(--color-white-light);
}*/
.nav-test-bar>ul li{
    text-align: center;
    display: inline-block;
    margin-right: 1em;
}

.nav-test-bar>ul li #ntb-bar-links {
    text-transform: capitalize;
                    text-decoration: none;
                    font-size: 22px;
                    font-weight: 600;
                    color: #ffffff;
                    padding: 14px;
                    line-height: 50px;
                    border-radius: 15px;
                    transition: 0.3s;
}

.nav-test-bar>ul li:hover #ntb-bar-links{
    opacity: 0.5;
    background-color: rgb(83, 210, 238);
    -webkit-box-shadow: 0 0 9px 0 rgba(112, 112, 112, 0.16); 
    box-shadow: 0 0 9px 0 rgba(112, 112, 112, 0.16);
    border-radius: 15px;
    cursor: pointer;
}

.nav-test-bar .Main-nav-img img:hover{
    opacity: 0.5;
    cursor: pointer;
}


.nav-test-download-glow{
    fill: #fff;
}
#download-b {
    height: 16px;
    width: 16px;
    cursor: pointer;
    background: transparent;
    transition-duration: 0.5ms;
}

#gitImg {
    object-fit: cover;
    vertical-align:middle;
    height: 50px;
    width: 50px;
}

#linkImg {
    vertical-align:middle;
    object-fit: cover;
    max-height: 40px;
    width: 40px;
}

.color-sep{
    background-color: var(--color-black-light);
    height: 10px;
    width: 100%;
}

.dropmenu{
    display: none;
}

.drop-down-cont {
    display: none;
}

.my-technologies-container{
    background-color: rgba(26, 17, 17, 0.659);
    border-radius: 15px;
    box-shadow: 0 0 30px 0 rgba(112, 112, 112, 0.16);
    flex-direction: column;
    display: flex;
    width: 85vw;
    z-index: 2;
    padding: 2em;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}


.my-technologies-container h2 {
    font-size: 45px;
    font-weight: 600;
    flex-grow: 4;
    color: #FFF;
    font-family: 'Open Sans', sans-serif;
    padding-bottom: 10px;
}

.my-technologies-container h3 {
    color: #FFF;
    font-family: 'Open Sans', sans-serif;
}

.my-technologies-outer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5px;
    margin-bottom: 5px;
    list-style: none;
    color: #f1f1f1;
}
.list-techno-inner {
    padding: 2px 5px 2px 5px;
    border-radius: 5px;
    margin-right: 10px;
    margin-top: 10px;
}


#techImgSize{
    height: 15px;
    width: 15px;
    object-fit: contain;
    vertical-align: middle;
}

#techImgTxt {
    margin-left: 8px;
    vertical-align: middle;
}

.color-back-tech{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(52,73,94,1);
    background-image:linear-gradient(0deg, rgba(255,255,255,1) 16%, rgba(162,222,186,1) 45%, rgba(52,73,94,1) 90%);
}

.color-back-tech>section{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
    box-shadow: 0 0 9px 0 rgba(112, 112, 112, 0.16);
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 10px;
    padding-bottom: 10px;
}


/*Home page*/
#home-background {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--color-black-dark2);
}


.home-page-back {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 15px;
    box-shadow: 0 0 9px 0 rgba(112, 112, 112, 0.16);
    max-width: 1400px;
    width: 85vw;
    z-index: 2;
    padding: 2em;
    
}

.inner-container-home{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif;
    margin: 25px;
}

.home-profile-links{
    display: inline;
    vertical-align: middle;
}

#nice-font {
    font-family: 'Open Sans', sans-serif;
    size: 16px;
    color: white;
    text-align: center;
}


.type-writer-container{
        margin: 20px;
        padding: 10px;
        background-color: #1e1b1b;
        width: 300px;
        height: 300px;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        border: rgb(153, 8, 8);
        border-style: solid;
        border-width: 1px;
        border-radius: 2%;
}

.type-writer-text {
    color: green;

}


/*examples page*/
.past-project-back{
    color: white;
    font-family: 'Open Sans', sans-serif;
    background-color: var(--color-black-dark-light);
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 100%;
}


.past-project-inner {
    background-color: rgba(150, 21, 219, 0.16);
    border-radius: 15px;
    /* offset-x | offset-y | blur-radius | spread-radius | color */
    box-shadow: 0 0 30px 10px rgba(150,21,219, 0.16);
    width: 85vw;
    z-index: 2;
    padding: 2em;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

.past-project-inner h2 {
    padding-bottom: 20px;
    font-size: 45px;
    font-weight: 600;
    flex-grow: 4;
    text-align: center;
}


.projects-carousel{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.example-proj-inner{
    width: 300px;
    display: flex;
    flex-direction: column;
    background-color: rgb(210,211,211);
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 3%;
}
.example-proj-inner-desc{
    list-style-position: outside;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 1em;
    margin-bottom: auto;
}

.example-proj-inner h5 {
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 16px;
    padding-left: 5px;
    color: #000000;
}

.jsonlist-info{
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    padding-top: 5px ;
    padding-bottom: 10px;
}

.exampleICONS {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  height: 220px;
  object-fit: cover;
  border-top-left-radius: 2%;
  border-top-right-radius: 2%;
}

.example-proj-inner-footer{
   background-color: #926f6f;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 5px;
}

.example-proj-inner-footer a {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #555;
    border-radius: 15px;
    height: 50px;
    width: 60px;
}

.example-proj-inner-footer a:hover {
    background-color: #889ab1;
}

/*---------------------*/
/*Hire Us Page*/
.hire-option-back{
    color: white;
    font-family: 'Open Sans', sans-serif;
    background-color: var(--color-black-dark-light);
    padding-top: 30px;
    padding-bottom: 30px;
}


.hire-option-inner-background {
    background-color: rgba(21, 163, 219, 0.16);
    border-radius: 15px;
    /* offset-x | offset-y | blur-radius | spread-radius | color */
    box-shadow: 0 0 30px 10px rgba(21, 163, 219, 0.16);
    width: 85vw;
    z-index: 2;
    padding: 10px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}
.hire-option-inner-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.hire-option-inner-options{
    width: 400px;
    display: flex;
    flex-direction: column;
    color: #000000;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 3%;
}

.hire-option-inner-background h2 {
    text-align: center;
    padding: 10px;
}

.hire-option-inner-background h4 {
    text-align: center;
    padding: 10px;
}


.hire-option-inner-options ul{
    list-style-position: outside;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 1em;
    padding-bottom: 5px;
    margin-bottom: auto;
}

.hire-option-inner-options p {
    padding: 5px;
}

#hire-options-mttl {
    font-size: 45px;
    font-weight: 600;
    flex-grow: 4;
}

#hr-optionsline {
    width: 250px; 
    margin-left: auto;
    margin-right: auto;
    height:10px;
    background-color:rgb(28, 28, 28); 
    border: none; 
    margin-bottom: 10px;
}


/* Carousel Portfolio */
.portfolio-carousel-back {
    color: white;
    font-family: 'Open Sans', sans-serif;
    background-color: var(--color-black-dark-light);
    position: relative;
    display:block;
    border: none !important;
    /*display: flex;
    flex-wrap: wrap;
    width: 85vw;
    justify-content: center;
    align-items: center;*/
}

.carousel-container-portfolio {
    /*display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 80%;*/
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    /* offset-x | offset-y | blur-radius | spread-radius | color */
    width: 85vw;
    z-index: 2;
    padding: 2em;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

.carousel-container-slides {
    /*outline: 1px dotted red;*/
    position: relative;
    border-radius: 15px;
    width: 100%;
    height: 400px;
    overflow:hidden;
}

.carousel-container-slides-inner {
    /*display: block;*/
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.slide-hidden {
     display: none;
}


.carousel-container-slides-inner img {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 80%;
    object-fit: cover;
    /*border-radius: 15px;*/
    border-top-left-radius: 2%;
    border-top-right-radius: 2%;
}

/*description of the image carousel*/
/*
.carousel-description {
    position: absolute;
    height: 55px;
    padding: 1%;
    padding-right: 280px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #5a2e98;
}*/

.carousel-description {
    position: relative;
    background-color: #5a2e98;
    height: 20%;
    margin-top: -4px;
}

.carousel-description .carousel-title {
    font-size: 22px;
    line-height: 1.1em;
    font-weight: 600;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-top: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 90%;
}

.carousel-description .carousel-details {
    font-size: 13px;
    color: #c4ace6;
    line-height: 1.5em;
    padding-left: 10px;
    margin-bottom: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-right: 20%;
}

.carousel-description .carousel-link {
    position: absolute;
    height: 3rem;
    width: 3rem;
    top: 20px;
    right: 2%;
    bottom: 20px;
}

.carousel-description .carousel-link .carousel-link-btn {
    object-fit: cover;
    vertical-align:middle;
    height: 3rem;
    width: 3rem;
    background-color: #fff;
    border-radius: 50px;
}


/*Portfolio arrows carousel */
.carousel-button-background {
    position: absolute;
    top: 45%;
    height: 5rem;
    width: 30px;
}
/*
.carousel-button-background:hover {
    opacity: 0.5;
}*/

.carousel-arrow {
    position: relative;
    width: 100%;
    height: 5rem;
    background-color: #889ab174;
    /*background-color: transparent;*/
    border: 1px solid transparent;
    cursor: pointer;
    opacity: 0;
    transition: all 0.5s;
}


.carousel-arrow:hover {
    opacity: 1;
    background: #889ab1b5;
}

.carousel-left {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    left: -15px;
}

.carousel-right {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    right: -15px;
}

/*SHOWS THE ARROWS WHEN HOVERING OVER THE GENERAL CONTAINER*/
.carousel-container-slides:hover .carousel-arrow {
    opacity: 0.75;
}

/*Moves arrows into view*/
.carousel-container-slides:hover .carousel-arrow.carousel-left {
    left: 14px;
}

.carousel-container-slides:hover .carousel-arrow.carousel-right {
    right: 14px;
}

.carousel-container-slides:hover .carousel-arrow.carousel-left:hover {
    opacity: 1;
}

.carousel-container-slides:hover .carousel-arrow.carousel-right:hover {
    opacity: 1;
}

/*Carousel Indicators Work Carousel*/
.indicators {
    display: flex;
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.indicator {
    background-color: #dd00ff;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    border: none;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin: 0 0.2rem;
    cursor: pointer;
}

.indicator-inactive {
    background-color: #f1f1f1;
}

.under-construction-back {
    color: white;
    font-family: 'Open Sans', sans-serif;
    background-color: var(--color-black-dark-light);
    display:flex;
    border: none !important;
}

.under-construction {
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
}

.under-construction img {
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    object-fit: cover;
    border-radius: 15px;
}

.under-construction-back h1 {
    text-align: center;
    line-height: 50px;
    height: 100px;
    font-size: 32px;
    font-weight: 700;
}

/*About page carousel */
.about-carousel-container-outside {
    background-color: rgba(26, 17, 17, 0.659);
    border-radius: 15px;
    box-shadow: 0 0 30px 0 rgba(112, 112, 112, 0.16);
    flex-direction: column;
    display: flex;
    width: 85vw;
    z-index: 2;
    padding: 2em;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    position: relative;
    overflow: hidden;
}


.about-carousel-container-outside h2 {
    font-size: 45px;
    font-weight: 600;
    flex-grow: 4;
    color: #FFF;
    font-family: 'Open Sans', sans-serif;
    padding-bottom: 10px;
}

.about-carousel-container {
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    align-self: center;
    width: 85vw;
    color: #FFF;
}

.about-carousel-header {
    flex-direction: column;
    text-align: right;
}

.about-company-name {
    font-size: 35px;
    font-weight: 600;
    line-height: 50px;
}

.about-company-role {
    font-size: 16px;
}

.about-company-date {
    font-size: 16px;
}

.about-company-location {
    font-size: 16px;
}

.about-company-desc-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.about-company-desc{
    margin-top: 0.75em;
    line-height: 1.5em;
    font-size: 15px;
    font-weight: normal;
    text-align: left;
}

.about-experience-title {
    font-size: 45px;
    font-weight: 600;
    flex-grow: 4;
    color: #FFF;
}

.about-carousel-container-slides {
    /*outline: 1px dotted red;*/
    position: relative;
    overflow:hidden;
}

/*arrows carousel About*/
.about-carousel-button-background {
    position: absolute;
    top: 50%;
    height: 5rem;
    width: 30px;
}

.about-carousel-arrow {
    position: relative;
    width: 100%;
    height: 5rem;
    background-color: #889ab174;
    /*background-color: transparent;*/
    border: 1px solid transparent;
    cursor: pointer;
    opacity: 0;
    transition: all 0.5s;
}


.about-carousel-arrow:hover {
    opacity: 1;
    background: #889ab1b5;
}

.about-carousel-left {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    left: -15px;
}

.about-carousel-right {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    right: -15px;
}


/*SHOWS THE ARROWS WHEN HOVERING OVER THE GENERAL CONTAINER*/
.about-carousel-container-outside:hover .about-carousel-arrow {
    opacity: 0.75;
}

/*Moves arrows into view*/
.about-carousel-container-outside:hover .about-carousel-arrow.about-carousel-left {
    left: 15px;
}

.about-carousel-container-outside:hover .about-carousel-arrow.about-carousel-right {
    right: 15px;
}

.about-carousel-container-outsides:hover .about-carousel-arrow.about-carousel-left:hover {
    opacity: 1;
}

.about-carousel-container-outside:hover .about-carousel-arrow.about-carousel-right:hover {
    opacity: 1;
}

/*About Carousel Indicators*/
.about-indicators {
    display: flex;
    position: absolute;
    top: 4rem;
    right: 2rem;
}

.about-indicator {
    background-color: #dd00ff;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    border: none;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin: 0 0.2rem;
    cursor: pointer;
}

.about-indicator-inactive {
    background-color: #f1f1f1;
}




/*
testing before functionality 
.carousel-arrow::before {
    content: "Read this -";
    background-color: yellow;
    color: red;
    font-weight: bold;
  }
*/

/*
carousel newer but old

.carousel-container {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    background-color: #889ab1;
}


.carousel-container img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 220px;
    object-fit: cover;
    border-top-left-radius: 2%;
    border-top-right-radius: 2%;
}
*/










/*
carousel old css


.carousel-inner {
    height: 200px;
    min-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.portfolio-carousel-inner {
    width: 600px;
    white-space: nowrap;
    transition: transform 0.3s;
}
.carousel-inner img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 2%;
    border-top-right-radius: 2%;
}
*/








/*
#email-box-group {
    top: -4em;
    position: relative;
    justify-content: center;
    background-color: rgba(52,73,94,1);
}
.email-box-subgroup {
    margin: 40px 40px;
    background-color: #2980B9;
    display: flex;
    align-items: center;
    justify-content: center;
}
.email-box-subgroup form {
    margin: 40px;
    background-color: #999;
}*/




/*
.mainArticle {
    border: 2px solid black;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 3%;
    margin-bottom: 3%;
    box-sizing: border-box;
}
.mainArticle p {
    padding: 0 30px;
    display: inline-block;
    background-color: #606e77;
}
*/